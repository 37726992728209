import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Sidan saknas" />
    <h1>Sidan saknas</h1>
    <p>Tyvärr finns inte den här sidan just nu.</p>
  </Layout>
)

export default NotFoundPage
